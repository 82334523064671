<template>
  <div class="border-t border-b border-black-900 -mt-px wysiwyg-content">
    <div class="flex items-center justify-between cursor-pointer" @click="toggle = !toggle">
      <slot name="title"></slot>
      <span class="minus inline-flex justify-center items-center w-11.5 h-11.5 text-dark-blue" v-if="toggle">-</span>
      <icon-plus
        class="stroke-current fill-current text-black !min-w-[15px] !min-h-[15px] !w-11.5 !h-11.5"
        v-else
      ></icon-plus>
    </div>
    <div class="transition-height duration-300 overflow-hidden max-h-0" :style="toggleHeight">
      <div ref="content">
        <div v-html="content_formatted" class="mt-4"></div>
        <div class="w-full pb-4 inline-flex justify-end" v-if="to">
          <nuxt-link :to="to">{{ t("read-more") }}</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from "~/assets/svg/plus.svg";

import ComputeViewportWith from "~/mixins/compute-viewport-width";

export default {
  name: "AccordionBasic",
  mixins: [ComputeViewportWith],
  components: {
    IconPlus,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: false,
      default: undefined,
    },
    textarea: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n({
      useScope: "local",
    });

    return { t };
  },
  data() {
    return {
      toggle: false,
      content_height: null,
    };
  },
  computed: {
    content_formatted() {
      if (this.content) {
        if (this.textarea) {
          return this.content.replace(/\n/g, "<br />");
        } else {
          return this.content;
        }
      }
      return "";
    },
    toggleHeight() {
      return this.toggle ? ` max-height: ${this.content_height}px` : "max-height: 0";
    },
  },
  methods: {
    compute_height() {
      this.content_height = this.$refs.content.clientHeight + 32;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.compute_height();
        window.addEventListener("resize", this.compute_height, true);
      }
    });
  },
};
</script>

<style lang="scss">
.max-h-9999px {
  max-height: 9999px !important;
}
</style>

<i18n lang="json">
{
  "fr": {
    "read-more": "Lire plus"
  },
  "en": {
    "read-more": "Read more"
  }
}
</i18n>
